import {BaseRequest} from "@/request/BaseRequest";

class PreCustomer extends BaseRequest {

    public requestPath: string = '/customer/preCustomer';

    /**
     * 同步数据
     * @param id
     */
    syncData(id: string): any {
        return this.post(`${this.requestPath}/syncData`, {id: id})
    }

}

const c = new PreCustomer();
export {c as PreCustomer}