



























































































































import {Vue, Component} from "vue-property-decorator";

import {PreCustomer} from "@/request/customer/PreCustomer";
import {MemberSet} from "@/request/customer/MemberSet";

@Component({
    name: 'customer_preCustomer'
})
export default class customer_preCustomer extends Vue {
    modal: any = PreCustomer;
    search: Array<any> = [
        {
            type: 'Input',
            label: '姓名',
            key: 'name',
            width: '120px',
            placeholder: '请填写姓名'
        },
        {
            type: 'Input',
            label: '电话',
            key: 'phone',
            width: '140px',
            placeholder: '请填写电话'
        }
    ];
    provinceList: Array<any> = [];
    cityList: Array<any> = [];
    memberList: Array<any> = [];
    formData: any = {
        name: '',
        phone: '',
        integral: 0,
        memberLv: 0,

        addressStr: '',
        birthday: '',
        career:'',
        cityCode: '',
        company: '',
        email: '',
        files: [],
        provinceCode: '',
        remark: '',
        wx: ''
    };
    formRules: any = {
        name: {required: true, trigger: 'blur'},
        phone: {required: true},
        integral: {required: true},
        memberLv: {required: true},
    };
    columns: Array<any> = [
        {
            title: '姓名',
            key: 'name',
            minWidth: 120
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '积分',
            key: 'integral',
            minWidth: 120
        },
        {
            title: '等级',
            key: 'memberName',
            minWidth: 120
        },
        /*{
            title: '店铺名称',
            key: 'store',
            minWidth: 120
        },*/
        {
            title: '是否同步',
            key: 'syncData',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.syncData===0?'未同步': '已同步')
        },
        {
            title: '添加人',
            key: 'employee',
            minWidth: 120
        },
        {
            title: '地址',
            key: 'addressStr',
            minWidth: 180,
            tooltip: true
        },
        {
            title: '职业',
            key: 'career',
            minWidth: 140
        },
        {
            title: '公司名称',
            key: 'company',
            minWidth: 150,
            tooltip: true
        },
        {
            title: '备注',
            key: 'remark',
            minWidth: 200,
            tooltip: true
        },
        {
            title: '微信',
            key: 'wx',
            minWidth: 150
        },
        {
            title: '邮箱',
            key: 'email',
            minWidth: 150,
            tooltip: true
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {
        MemberSet.getAll().then((body: any) => {
            this.memberList = body.data || [];
        })
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    syncData(row: any) {
        const msg: any = this.$Message.loading({
            content: '加载中...',
            duration: 0
        });
        PreCustomer.syncData(row.id).then((body: any) => {
            setTimeout(msg, 500);
            if (body.code === 0) {
                this.$Message.info('同步成功');
            }
        })
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-table'].doSubmitWithValidate(params).then(() => {

        }).catch(() => {})
    }
}
